<template>
  <list v-if="entity" :entity="entity" :filters="filters">
    <template slot="list" slot-scope="data">
      <custom-header id="report" :cols="customHeader"></custom-header>
      <b-table
        id="report"
        class="edge"
        :fields="table.fields"
        :items="rows"
        :filter="filter"
        :sort-by="data.sort.by"
        :sort-desc="data.sort.desc"
        @sort-changed="$emit('sortChange', $event)"
        no-local-sorting
        responsive
      >
        <template slot="name" slot-scope="data">
          <expander
            :level="data.item.level"
            :has-items="hasItems(data.item)"
            :expanded="data.item._expanded"
            @expand="expand(data.item)"
          >
            <span slot="text" @click="expand(data.item)">
              <responsible v-if="data.item.user" :responsible="data.item.user" class="mr-5"></responsible>
              {{ data.value }}
            </span>
          </expander>
        </template>
        <template v-for="period in ['new', 'current', 'completed']" :slot="period + '_amount'" slot-scope="data">
          {{ data.value || 0 | formatNumber }} &#8381;
        </template>

        <template v-for="period in ['new', 'current', 'completed']" :slot="period + '_in_work'" slot-scope="data">
          {{ data.value || 0 | formatNumber }} &#8381;
        </template>

        <template
          v-for="period in ['new', 'current', 'unload', 'completed', 'plan']"
          :slot="period + '_count'"
          slot-scope="data"
        >
          <template v-if="period === 'plan'">
            <div :id="'acts' + data.index">
              <span v-if="data.item['planned_acts_count']" @click="toActWithPlanDate(data)" class="to-act">
                {{ data.item['planned_acts_count'] || 0 }}
              </span>
              <span v-else>0</span>
            </div>
          </template>
          <b-link v-else-if="period !== 'unload'" :to="linkToProjects(period, data.item)" target="_blank">
            {{ data.value | formatNumber }}
          </b-link>
          <template v-else>
            <div :id="'acts' + data.index">
              <span v-if="data.item['signed_acts_count']" @click="toAct(data)" class="to-act">
                {{ data.item['signed_acts_count'] }}
              </span>
              <span v-else>0</span>
            </div>
          </template>
        </template>

        <template v-for="period in ['new', 'current', 'completed']" :slot="period + '_link'" slot-scope="data">
          <b-link :to="linkToProjects(period, data.item)" v-b-tooltip.hover title="Открыть список проектов"
            ><i class="icon zmdi zmdi-format-list-bulleted" aria-hidden="true"></i
          ></b-link>
        </template>

        <template v-for="period in ['new', 'current', 'completed']" :slot="period + '_hours_balance'" slot-scope="data">
          {{ data.value | formatDuration(undefined, undefined, 'hours') }}
        </template>

        <!--Расход часов-->
        <template v-for="period in ['new', 'current', 'completed']" :slot="period + '_hours_fact'" slot-scope="data">
          <div :id="period + '_hours_fact-row_' + data.index">
            <progress-bar :value="data.item[period + '_hours_mastering_percent'] || 0" :width="10" reverse>
              {{ data.value | formatNumber }}ч / {{ data.item[period + '_hours_plan'] | formatNumber('0,0.[0]') }}ч ({{
                data.item[period + '_hours_mastering_percent'] | formatNumber
              }}%)
            </progress-bar>
          </div>

          <popover :target="period + '_hours_fact-row_' + data.index"></popover>
        </template>

        <!--Себестоимость-->
        <template v-for="period in ['new', 'current', 'completed']" :slot="period + '_worth'" slot-scope="data">
          <progress-bar :value="data.item[period + '_worth_percent'] || 0" :width="10" reverse>
            {{ data.value | formatNumber }} &#8381; ({{ data.item[period + '_worth_percent'] | formatNumber }}%)
          </progress-bar>
        </template>

        <!--Маржа-->
        <template v-for="period in ['new', 'current', 'completed']" :slot="period + '_margin'" slot-scope="data">
          {{ data.item[period + '_margin'] || 0 | formatNumber }} &#8381; ({{
            data.item[period + '_margin_percent'] | formatNumber
          }}%)
        </template>

        <!--Марж. отгрузка-->
        <template v-for="period in ['new', 'current', 'completed']" :slot="period + '_mo'" slot-scope="{ value }">
          {{ value || 0 | formatNumber }} &#8381;
        </template>

        <!--Отгружено, Потенциал-->
        <template v-for="period in ['signed', 'planned']" :slot="period + '_acts_cost'" slot-scope="{ value }">
          {{ value || 0 | formatNumber }} &#8381;
        </template>

        <template
          v-for="period in ['new', 'current', 'completed']"
          :slot="period + '_direct_expenses'"
          slot-scope="{ value }"
        >
          {{ value || 0 | formatNumber }} &#8381;
        </template>

        <!--ШАПКА-->
        <!--Марж. отгрузка-->
        <template v-for="period in ['new', 'current', 'completed']" :slot="'HEAD_' + period + '_mo'" slot-scope="data">
          <th-extended :data="data">
            <template slot="popover"> Маржинальная отгрузка = Бюджет - Расходы</template>
          </th-extended>
        </template>

        <!--Прямые расходы-->
        <template
          v-for="period in ['new', 'current', 'completed']"
          :slot="'HEAD_' + period + '_direct_expenses'"
          slot-scope="data"
        >
          <th-extended :data="data">
            <template slot="popover">
              Прямые расходы по сделке из виджета "Платежи"<br />
              (оплаченные/неоплаченные)
            </template>
          </th-extended>
        </template>

        <!--Себестоимость-->
        <template
          v-for="period in ['new', 'current', 'completed']"
          :slot="'HEAD_' + period + '_worth'"
          slot-scope="data"
        >
          <th-extended :data="data">
            <template slot="popover"> Расчётная себестоимость по списанному времени</template>
          </th-extended>
        </template>

        <!--Маржа-->
        <template
          v-for="period in ['new', 'current', 'completed']"
          :slot="'HEAD_' + period + '_margin'"
          slot-scope="data"
        >
          <th-extended :data="data">
            <template slot="popover"> Валовая прибыль = Бюджет - Расходы - Себестоимость</template>
          </th-extended>
        </template>
      </b-table>
    </template>
  </list>
</template>

<script>
  import { getUsersDropdown } from '../../../assets/js/common'

  import qs from 'qs'
  import remove from 'lodash/remove'
  import get from 'lodash/get'
  import flatten from 'lodash/flatten'

  import { mapState } from 'vuex'

  import entityMixins from '../../../mixins/entity'
  import stairsMixins from '../../../mixins/stairs'

  import List from '../../../components/List'
  import Popover from '../../../components/PopoverAlive'
  import Expander from '../../../components/Expander'
  import CustomHeader from '../../../components/TableHeader'
  import Responsible from '../../../components/Responsible'
  import ProgressBar from '../../../components/ProgressBar'
  import ThExtended from '../../../components/ThExtended'
  import { isEmpty, sum, compact, uniq } from 'lodash'
  import moment from 'moment'

  export default {
    name: 'report',
    components: {
      ThExtended,
      List,
      Popover,
      Expander,
      CustomHeader,
      Responsible,
      ProgressBar,
    },
    mixins: [entityMixins, stairsMixins],
    data() {
      return {
        isEmpty,
        compact,
        qs,
        filters: [
          {
            name: 'date',
            type: 'date',
            range: true,
            legend: 'Период',
          },
          {
            label: 'Менеджер',
            name: 'manager_id',
            type: 'select',
            multiple: true,
            items: [],
            roles: ['manager'],
          },
        ],
        table: {
          fields: [
            {
              key: 'name',
              label: 'Менеджеры',
            },
          ].concat(
            flatten(
              ['new', 'current', 'plan', 'unload', 'completed'].map(period => {
                let fields = [
                  {
                    key: period + '_delimiter',
                    label: '&nbsp;',
                    class: 'delimiter mid-delimiter',
                  },
                  {
                    key: period + '_count',
                    label: 'Кол-во',
                    class: 'text-center',
                    sortable: true,
                  },
                  {
                    key: 'planned_acts_cost',
                    label: 'План',
                    class: 'text-center',
                    sortable: true,
                    condition() {
                      return period === 'plan'
                    },
                  },
                  {
                    key: period + '_amount',
                    label: 'Бюджет',
                    class: 'text-center',
                    sortable: true,
                    condition() {
                      return period === 'new'
                    },
                  },
                  {
                    key: period + '_amount',
                    label: 'В работе',
                    class: 'text-center',
                    sortable: true,
                    condition() {
                      return period === 'current'
                    },
                  },
                  {
                    key: period + '_in_work',
                    label: 'Не отгружено',
                    class: 'text-center',
                    sortable: true,
                    condition() {
                      return period === 'current'
                    },
                  },
                  {
                    key: period + '_amount',
                    label: 'Закрыто',
                    class: 'text-center',
                    sortable: true,
                    condition() {
                      return period === 'completed'
                    },
                  },
                  {
                    key: 'signed_acts_cost',
                    label: 'Отгружено',
                    class: 'text-center',
                    sortable: true,
                    condition() {
                      return period === 'unload'
                    },
                  },
                  {
                    key: period + '_direct_expenses',
                    label: 'Прямые расходы',
                    class: 'text-center progress-col',
                    sortable: true,
                    roles: ['admin'],
                    condition() {
                      return period === 'completed'
                    },
                  },
                  {
                    key: period + '_mo',
                    label: 'Марж. закрытых',
                    class: 'text-center progress-col',
                    sortable: true,
                    condition() {
                      return period === 'completed'
                    },
                  },
                  {
                    key: period + '_worth',
                    label: 'Себестоимость',
                    class: 'text-center progress-col',
                    sortable: true,
                    roles: ['admin'],
                    condition() {
                      return period === 'completed'
                    },
                  },
                  {
                    key: period + '_margin',
                    label: 'Валовая прибыль',
                    class: 'text-center progress-col',
                    sortable: true,
                    roles: ['admin'],
                    condition() {
                      return period === 'completed'
                    },
                  },
                  {
                    key: period + `_hours_fact`,
                    label: 'Расход часов',
                    class: 'text-center progress-col',
                    sortable: true,
                    condition() {
                      return period === 'current'
                    },
                  },
                  {
                    key: period + '_hours_balance',
                    label: 'Остаток часов',
                    class: 'text-center progress-col',
                    sortable: true,
                    condition() {
                      return period === 'current'
                    },
                  },
                ]

                fields = fields.filter(f => !f.condition || f.condition())

                return fields
              })
            )
          ),
        },
      }
    },
    computed: {
      customHeader() {
        let isAdmin = this.positions.indexOf('admin') >= 0
        return [
          {
            label: 'Пришло в работу',
            slice: [3, 2],
          },
          {
            label: 'Сейчас в работе',
            slice: [6, 5],
          },
          {
            label: 'Потенциал отгрузки',
            slice: [12, 2],
          },
          {
            label: 'Отгружено',
            slice: [15, 2],
          },

          {
            label: 'Закрыто',
            slice: [18, isAdmin ? 7 : 4],
          },
        ]
      },
      ...mapState({
        positions: state => state.auth.user.positions,
      }),
    },
    methods: {
      logger(...args) {
        console.log(...args)
      },
      countPlanDate(data) {
        if (data.item.acts && data.item.acts.length) {
          const withPlanDate = data.item.acts.filter(({ plan_date }) => plan_date)
          return withPlanDate.length ? sum(withPlanDate.map(({ cost }) => +cost)) : 0
        }
      },
      openBlank(query) {
        window.open(`/reports/acts?${qs.stringify(query, { arrayFormat: 'brackets' })}`)
      },
      async toActWithPlanDate(data) {
        const acts = data.item.acts.filter(({ plan_date }) => plan_date)
        if (isEmpty(this.$route.query)) {
          this.openBlank({
            responsibleUsers: uniq(acts.map(e => +e.responsible_user)),
            planDate: [moment().startOf('month').format('DD-MM-YYYY'), moment().format('DD-MM-YYYY')],
          })
        } else {
          const query = this.$route.query.filters && this.$route.query.filters[this.$route.path.slice(1)]
          let newQuery = {}
          if (query && !isEmpty(query)) {
            for (const key in query) {
              const value = query[key]
              if (key === 'date') {
                const dateValuesArray = Object.values(value)
                if (dateValuesArray.length === 1) dateValuesArray.push(moment().format('DD-MM-YYYY'))
                newQuery = { ...newQuery, planDate: dateValuesArray.map(d => moment(d).format('DD-MM-YYYY')) }
              }
              if (key === 'manager_id') {
                newQuery = {
                  ...newQuery,
                  responsibleUsers: uniq(acts.map(e => +e.responsible_user)),
                }
              }
            }
            newQuery.responsibleUsers = {
              ...newQuery.responsibleUsers,
              ...(data.index !== 0 && uniq(acts.map(e => +e.responsible_user))),
            }
            this.openBlank(newQuery)
          }
        }
      },
      async toAct(data) {
        const acts = data.item.acts
        if (isEmpty(this.$route.query)) {
          this.openBlank({
            responsibleUsers: data.index !== 0 ? uniq(acts.map(e => +e.responsible_user)) : [],
            dateConfirm: [moment().startOf('month').format('DD-MM-YYYY'), moment().format('DD-MM-YYYY')],
            actStatus: 'Подписан',
          })
        }
        const query = this.$route.query.filters && this.$route.query.filters[this.$route.path.slice(1)]

        let newQuery = {}
        if (query && !isEmpty(query)) {
          for (const key in query) {
            const value = query[key]
            if (key === 'date') {
              const dateValuesArray = Object.values(value)
              if (dateValuesArray.length === 1) dateValuesArray.push(moment().format('DD-MM-YYYY'))
              newQuery = { ...newQuery, dateConfirm: dateValuesArray.map(d => moment(d).format('DD-MM-YYYY')) }
            }
            if (key === 'manager_id') {
              newQuery = {
                ...newQuery,
                responsibleUsers: uniq(acts.map(e => +e.responsible_user)),
              }
            }
          }
          newQuery.responsibleUsers = {
            ...newQuery.responsibleUsers,
            ...(data.index !== 0 && uniq(acts.map(e => +e.responsible_user))),
          }
          this.openBlank({
            ...newQuery,
            actStatus: 'Подписан',
          })
        }
      },
      resultHandler(result) {
        let items = []

        result.items.forEach(item => {
          let subItems = item.items

          subItems.forEach(i => {
            if (item.id === 'none' && i.id === 'none') {
              i.user = {
                id: 'none',
              }
            }
          })

          let users = subItems.filter(i => i.user)
          remove(item.items, i => i.user)

          // Помечаем отделы
          if (item.id !== 'summaryString') {
            item.department = true
          } else {
            item.summary = true
          }

          items.push(
            Object.assign(item, {
              _rowVariant: 'bold',
            })
          )

          // Юзеров выносим на тот же уровень
          users.forEach(user => {
            items.push(user)
          })

          // Добавляем разделитель
          items.push({ _rowVariant: 'delimiter mid-delimiter' })
        })

        result.items = items
        result.items = this.dataPrepare(result)
      },
      linkToProjects(period, item) {
        let params = {
          preset: 'allActiveAndArchive',
        }

        // Пользователь
        let user = item.user || get(item, 'parent.user')
        if (user) {
          params.project_manager_id = (user.id !== 'none' && 'user_' + user.id) || 'none'
        }

        // Отдел
        let departmentId = (item.department && item.id) || (get(item, 'parent.department') && item.parent.id)
        if (departmentId) {
          params.project_manager_id = (departmentId !== 'none' && 'department_' + departmentId) || 'none'
        }

        // В работе
        if (period === 'current') {
          params.preset = 'allInWork'
        }

        // Тип сделки
        if (item.id && !item.user && !item.department && !item.summary) {
          if (item.id !== 'none') {
            params.lead_type_id = item.id.replace('type_', '')
          }
        }

        // Фильтр даты
        let date = this.module.state.filters.date
        if (date) {
          switch (period) {
            case 'new':
              params.project_start = date
              break
            case 'completed':
              params.project_end = date
              break
          }
        }

        return (
          '/projects?' +
          qs.stringify({
            filters: {
              projects: params,
            },
          })
        )
      },
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        const managersFilter = vm.filters.find(filter => filter.name === 'manager_id')
        if (managersFilter) {
          getUsersDropdown().then(items => {
            managersFilter.items = items
          })
        }
      })
    },
  }
</script>

<style lang="scss" scoped>
  /deep/ {
    .to-act {
      color: #20a8d8;
      cursor: pointer;
    }
    .filters {
      margin-bottom: 15px !important;
    }

    table {
      .table-bold {
        font-weight: 600;
      }

      thead {
        text-align: center;
      }

      tr {
        td {
          &:first-child {
            padding-left: 20px;
          }
        }

        &:first-child {
          th {
            &:first-child {
              width: 300px;
            }
          }
        }

        th.progress-col {
          width: 150px !important;
        }
      }
    }

    .progress-bar {
      transition: unset !important;
    }

    .table-summary {
      font-weight: 600;
    }
  }
</style>
