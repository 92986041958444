<template>
  <div>
    <!--Label-->
    <span :id="generatedId">
      <slot>{{ field.label }}</slot>
    </span>

    <!--Popover-->
    <popover v-if="$slots.popover" :target="generatedId">
      <slot name="popover" />
    </popover>

    <!--Expander-->
    <template v-if="field.expander && fields">
      <fa :icon="`angle-${!field.expander.closed ? 'left' : 'right'}`" @click="expand" class="mx-5 expander-icon" />
    </template>
  </div>
</template>

<script>
  import uniqid from 'uniqid'
  import get from 'lodash/get'

  import Popover from './PopoverAlive'

  export default {
    name: 'ThExtended',
    props: ['data', 'fields'],
    components: {
      Popover,
    },
    data() {
      return {
        generatedId: uniqid('col-'),
      }
    },
    computed: {
      field() {
        return this.data.field
      },
      fieldIndex() {
        return (this.fields || []).findIndex(f => f.key === this.field.key)
      },
      closed() {
        return get(this.field, ['expander', 'closed'], null)
      },
    },
    methods: {
      expand() {
        this.closed ? this.open() : this.close()
        this.$set(this.field.expander, 'closed', !this.closed)
      },
      open() {
        if (this.fieldIndex !== -1) {
          this.field.expander.saved.forEach((field, index) => {
            this.fields.splice(this.fieldIndex + 1 + index, 0, field)
          })
        }
      },
      close() {
        if (this.fieldIndex !== -1) {
          this.$set(this.field.expander, 'saved', this.fields.splice(this.fieldIndex + 1, this.field.expander.include))
        }
      },
    },
    created() {
      if (this.closed && !this.field.saved) {
        this.close()
      }
    },
  }
</script>
